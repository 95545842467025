
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserPermissions } from "@/store/types";
import SiteMonitorHeader from "@/components/SiteMonitorHeader.vue";
import EnhancedMap from "@/components/EnhancedMap.vue";
import EventTaskContainer from "@/components/tasks/EventTaskContainer.vue";
import EventClose from "@/components/EventClose.vue";
import EventRaise from "@/components/EventRaise.vue";
import AreaNotes from "@/components/AreaNotes.vue";
import EventSharing from "@/components/EventSharing.vue";
import EventLeave from "@/components/EventLeave.vue";
import IncidentReport from "@/components/IncidentReport.vue";
import ErrorWarning from "@/components/ErrorWarning.vue";
import EventEnded from "@/components/EventEnded.vue";
import ForceRestore from "@/components/ForceRestore.vue";
import InactivityWarning from "@/components/site-monitor/InactivityWarning.vue";
import AreaNotesModal from "@/components/area-notes/AreaNotesModal.vue";
import PutEventOnTestModal from "@/components/site-monitor/PutEventOnTestModal.vue";
import FieldOps from './FieldOps.vue';
import EnhancedSingleMap from '@/components/EnhancedSingleMap.vue';
import EventRecords from "@/components/EventRecords.vue";
import EqStatusInfoBar from '@/components/EqStatusInfoBar.vue';
import api from "@/services/api.service";
import EnhancedMapSidebar from '@/components/EnhancedMapSidebar.vue';
import SureViewIcon from "@/components/SureViewIcon.vue";
import SiteMonitorMixin from '@/mixins/SiteMonitorMixin';

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"site-monitor-header": SiteMonitorHeader,
		"event-records": EventRecords,
		"enhanced-map": EnhancedMap,
		"enhanced-single-map": EnhancedSingleMap,
		"task-container": EventTaskContainer,
		"event-close": EventClose,
		"event-raise": EventRaise,
		"area-notes": AreaNotes,
		"area-notes-modal": AreaNotesModal,
		"event-sharing": EventSharing,
		"event-leave": EventLeave,
		"incident-report": IncidentReport,
		"error-warning": ErrorWarning,
		"event-ended": EventEnded,
		"force-restore": ForceRestore,
		"inactivity-warning": InactivityWarning,
		"put-event-on-test-modal": PutEventOnTestModal,
		"field-ops": FieldOps,
		"eq-status-info-bar": EqStatusInfoBar,
		"enhanced-map-side-bar": EnhancedMapSidebar,
		"sureview-icon": SureViewIcon,
	}
})
export default class EventEdit extends SiteMonitorMixin {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getPermissions") permissions: UserPermissions;

	private isReady: boolean = false;

	private created(): void {
		this.onCreate();
	}

	private async beforeMount(): Promise<void> {
		if (!this.canEditPostEvent) {
			this.$router.push("event-queue");
			return;
		}

		try {
			// Allow the viewing user to join the event.
			await api.joinClosedEvent(this.eventId);
		}
		catch (err) {
			this.$router.push("event-queue");
			return;
		}

		this.isReady = true;
	}

	private get canEditPostEvent(): boolean {
		return !!this.getFeature(["Alarms", "EventReview", "EditPostEvent"]) && !!this.permissions.canEditPostEvent;
	}
}
